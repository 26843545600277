<template>
  <div>
    <div class="closing-page">
      <div v-if="showSuccessSignModal" class="default-modal">
        <div class="default-modal__container">
          <img
            class="default-modal__close"
            alt="close"
            src="@/assets/images/close-groups-modal.svg"
            @click="handleCloseSuccessSignModal"
          />
          <div class="default-modal__body">
            <h2 v-html="modalTitle" />
            <p v-html="modalDescription" />
          </div>
          <div class="default-modal__footer">
            <button class="button-default" @click="handleCloseSuccessSignModal">
              Close
            </button>
          </div>
        </div>
      </div>
      <LoginModal
        :showLoginModal="showLoginModal"
        @onCloseLoginModal="handleCloseLoginModal"
      />
      <div class="internal-overlay-ellipse"></div>
      <div class="overlay-ellipse"></div>
      <div class="closing-page__header">
        <img alt="" src="@/assets/images/logo-beta-white.svg" />
        <div class="main-page__header__nav">
          <div class="d-flex justify-content-center align-items-center">
            <p class="login-text">Home/Login</p>
            <router-link :to="isAuthorized ? '/online-dashboard' : '/home'">
              <button class="button btn-login">
                <img alt="to home" src="@/assets/images/arrow-right.svg" />
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="closing-page__content">
        <h1 class="closing-page__title">Thank you.</h1>
        <div
          class="arrow-down d-flex justify-content-center align-items-center"
        >
          <img
            alt="arrow down"
            src="@/assets/images/main/arrow-down-white.svg"
          />
        </div>
        <h2 class="closing-page__subtitle">
          It is with heavy hearts<br />that we announce our closure.
        </h2>
        <div class="closing-page__text-block">
          <p>
            In less than four years, Aslice proved that real change in the music
            industry is possible. Our community platform was built by artists
            for artists. We developed a revenue-sharing software that
            worked–without any corporate funding or influence. Aslice has always
            been dedicated to ensuring music producers are compensated fairly,
            driven by a simple yet profound principle: share with the artists
            you love.
          </p>
          <p>
            Beyond the innovative technology we created from scratch, we
            fostered something much more meaningful: we strengthened connections
            between DJs and music producers worldwide. We made strides toward a
            fairer music ecosystem that prioritized the core values of respect
            and mutual support, making artists feel valued and acknowledged in a
            new way. Inspiring things can happen when we all work together.
          </p>
          <p>
            We take immense pride in what we've achieved. Our proprietary
            machine-learning software allowed us to reach an unparalleled 99.8%
            track matching accuracy, setting an industry benchmark, and proving
            that the right producers can receive their royalties when the right
            technology is implemented. The impact of the thousands of users who
            shared our vision helped everyone earn their fair share. 29% of
            producers received their first-ever payment for their music through
            Aslice, while 60% earned more from Aslice than all their other music
            income combined.
          </p>
          <p>
            <strong>
              Aslice is no longer accepting playlists. All money collected for
              producers will be paid out.
              <a @click="scrollToElement('disclaimer-box')">
                Learn more here.
              </a>
            </strong>
          </p>
          <p>
            In the process of closing, we sought out the data analysis experts
            at Audience Strategies to evaluate the wealth of data we gathered
            over our four years of operation. Their report ‘A Slice of Fairness’
            offers an independent, external look at the workings and impact of
            Aslice, the findings from our 2024 User Survey, and the challenging
            dynamics of the electronic music industry.
          </p>
          <p>
            <strong>
              Read the report “
              <a @click="scrollToElement('report')">A Slice of Fairness</a>
              ” by Audience Strategies on Aslice’s impact on the electronic
              music industry.
            </strong>
          </p>
          <p>
            To our friends, DJs, producers, partners, and advisors: thank you
            for demonstrating that a better future is possible through
            collective action. The electronic music world has always been a
            place to express creativity, nurture community, and support
            underrepresented voices. Striving for equity and fairness often
            begins with us, the artists and dreamers, who challenge the status
            quo. Although Aslice is closing, there is much more work to be done.
          </p>
          <p>
            To everyone who dedicated their time and energy to the Aslice
            movement: Keep speaking up. Continue to be loud and make your voices
            heard. Continue to demand better from the industry. Always advocate
            for one another, now and in the future.
          </p>
          <p>
            As you rise to a position of success in your career—we hope you will
            reach out and give back to others. Acts of kindness and generosity
            are the most important legacies we could ever leave behind.
          </p>
          <p>It has been an honor working for all of you.</p>
          <p>The Aslice Team</p>
          <div class="closing-page__faq-block">
            <span>Frequently Asked Questions About Aslice’s Closure</span>
            <a class="button" href="/faq" target="_blank" style="text-decoration: none">
              FAQS
            </a>
          </div>
        </div>
        <div class="divider"></div>
        <div class="closing-page__info-block" ref="disclaimer-box">
          <p>
            <strong>Details About Final Payouts:</strong> All registered Aslice
            artists with any remaining balance in the system will be paid out
            via payment method saved on file. All unregistered producers must
            sign up before December 31, 2024 to receive payment after account
            verification. Payouts will continue until all registered users are
            paid. Aslice will not retain any unclaimed earnings accumulated for
            music producers within the Aslice system, nor will any members of
            the staff, including the CEO, founder, or any partners. After final
            producer payouts are complete, all unaccounted-for earnings in the
            Aslice system will be donated via The Aslice Foundation, a 501(c)(3)
            non-profit organization. For details, see our
            <a href="/faq" target="_blank">FAQ</a> and
            <a href="/terms" target="_blank">Terms</a> pages.
          </p>
        </div>
        <div class="closing-page__report-block" id="search-block">
          <div>
            <h2 class="report-block__title">
              <div>music producers!</div>
              do you have MONEY WAITING IN aslice?
            </h2>
            <p>
              Search to see if you have earned any money in the Aslice system by
              checking all relevant artist names individually (including your
              primary production name, aliases, and group names).
            </p>
            <p>
              <strong style="text-decoration: underline">
                Deadline to register for final payment is on December 31, 2024.
              </strong>
            </p>
            <p>
              All unclaimed earnings left in Aslice’s system after the deadline
              and final payout date will be donated to charities chosen by
              <a href="/theaslicefoundation" target="_blank">
                The Aslice Foundation</a>.
            </p>
            <div v-if="false" class="report-block__search-box">
              <div v-if="currentState === 'search'" class="search-wrapper">
                <div class="search-field">
                  <input
                    v-model="artistName"
                    class="search-input"
                    placeholder="Artist Search"
                    type="text"
                    @keydown.enter="checkArtist"
                  />
                  <img
                    alt="search"
                    class="search-icon"
                    src="@/assets/images/search.svg"
                  />
                </div>
                <p class="search-instructions">
                  Search only one name at a time.
                </p>
                <button
                  :class="{ disabled: !artistName }"
                  class="button"
                  @click="checkArtist"
                >
                  Enter
                </button>
              </div>
              <div
                v-else-if="currentState === 'noMoney'"
                class="search-wrapper"
              >
                <p class="search-box__title search-box__title-red">Sorry</p>
                <p class="search-box__title">
                  There isn’t any money waiting for<br />
                  {{ artistName }}
                </p>
                <button class="button btn-outline" @click="resetSearch">
                  Search again
                </button>
              </div>
              <div
                v-else-if="currentState === 'registered'"
                class="search-wrapper"
              >
                <p class="search-box__title">{{ artistName }}</p>
                <p class="search-box__title">Is already registered.</p>
                <button
                  class="button btn-outline"
                  @click="handleShowLoginModal"
                >
                  Login
                </button>
                <button class="button btn-text" @click="resetSearch">
                  Search again
                </button>
              </div>
              <div
                v-else-if="currentState === 'hasMoney'"
                class="search-wrapper"
              >
                <p class="search-box__title search-box__title-green">Yes!</p>
                <p class="search-box__title">{{ artistName }}</p>
                <p class="search-box__title">Has Money waiting</p>
                <form class="email-form" @submit="signup($event)">
                  <div class="email-field">
                    <label for="email">Email</label>
                    <input
                      id="email"
                      v-model="email"
                      placeholder="Enter Email"
                      required
                      type="email"
                    />
                  </div>
                  <button
                    :class="{ disabled: !isValidEmail }"
                    class="button btn-outline"
                    type="submit"
                  >
                    Sign up
                  </button>
                  <button class="button btn-text" @click="resetSearch">
                    Search again
                  </button>
                </form>
              </div>
              <p v-if="currentState === 'noMoney'" class="search-info">
                If you believe this result is in error, please
                <a href="/contact" target="_blank">contact us</a>.
              </p>
              <p
                v-if="
                  currentState === 'registered' || currentState === 'hasMoney'
                "
                class="search-info"
              >
                A one-time ID-verification fee of $1.50 is charged for the first
                payout. If the accumulated earnings for an artist total $1.50 or
                less, no payout will be issued. For more info, see our
                <a href="/faq" target="_blank">FAQ</a> page.<br />
                If you believe this result is in error, please
                <a href="/contact" target="_blank">contact us</a>.
              </p>
            </div>
            <div class="report-block__search-box notice-closure">
              <h3 class="notice-closure__title">aslice registration is now closed.</h3>
              <p class="notice-closure__text">Existing users may access their account until April 1, 2025.</p>
            </div>
          </div>
          <div>
            <div ref="report">
              <img
                alt="report img"
                class="report-img"
                src="@/assets/images/main/images/report.jpg"
              />
            </div>
            <h2 class="report-block__title">
              read the report!
            </h2>
            <p>
              <strong>“A Slice of Fairness: 
                Aslice’s Successful Model For Rebalancing DJ And Producer Income
                Inequality”</strong> by Audience Strategies offers an
              independent, external look at the impact of Aslice, the findings from
              the 2024 Electronic Music Artist User Survey, and the challenging
              dynamics of the electronic music industry.
            </p>
            <router-link
              target="_blank"
              to="/s3/A+Slice+of+Fairness+-+Audience+Strategies+Aslice+Report+2024.pdf"
            >
              <button class="button">
                download Report
              </button>
            </router-link>
          </div>
        </div>
        <div class="closing-page__banner-block contact-us-banner">
          <p>
            For information about Aslice products and technology,
            <a href="/contact" target="_blank">Contact Us </a>
          </p>
          <div>
            <img alt="banner" src="@/assets/images/main/images/macbook-air.png" />
          </div>
        </div>
        <div class="d-none d-md-block divider"></div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Footer from "@/components/FooterNew";
import api from "@/service/api";
import LoginModal from "@/components/LoginModal.vue";
import swal from "sweetalert2";

const MODAl_TEXTES = {
  title: "Thank You For <br/>Signing Up",
  description: email => `
    A verification email has been sent to ${email} with instructions on how to register.
    <br/><br/>
    Please check your spam/promotions/social folder(s) and mark info@aslice.com as a trusted sender.
    `
};

export default {
  name: "ClosingPage",
  metaInfo: {
    title: "Aslice",
    meta: [
      {
        property: "og:description",
        content:
          "Aslice: Creating A Fairer Music Ecosystem | Registration is Free. Sign Up And Join The Community! Aslice is the revolutionary, new software-based service and community platform that allows working DJs to share earnings directly with the music producers they play at paid gigs. Follow us on social media: @asliceofficial"
      },
      {
        name: "description",
        content:
          "Aslice: Creating A Fairer Music Ecosystem | Registration is Free. Sign Up And Join The Community! Aslice is the revolutionary, new software-based service and community platform that allows working DJs to share earnings directly with the music producers they play at paid gigs. Follow us on social media: @asliceofficial"
      }
    ]
  },
  components: {
    LoginModal,
    Footer
  },
  data() {
    return {
      currentState: "search",
      artistName: "",
      email: "",
      showLoginModal: false,
      showSuccessSignModal: false,
      modalTitle: "",
      modalDescription: ""
    };
  },
  computed: {
    ...mapState("user", ["data", "isAuthorized"]),
    isValidEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return this.email && emailPattern.test(this.email);
    }
  },
  methods: {
    ...mapActions("app", ["hideBackground"]),
    async checkArtist() {
      console.log(this.artistName);
      try {
        const response = await api.checkArtistAccruals(this.artistName);
        console.log("Server response:", response);
        const result = response.Data;
        if (result && !result.registered && result.hasAccruals) {
          this.currentState = "hasMoney";
        } else if (result && result.registered) {
          this.currentState = "registered";
        } else {
          this.currentState = "noMoney";
        }
      } catch (error) {
        console.error("Error checking artist:", error);
      }
    },
    resetSearch() {
      this.currentState = "search";
      this.artistName = "";
    },
    handleShowLoginModal() {
      this.showLoginModal = true;
    },
    handleCloseLoginModal() {
      this.showLoginModal = false;
    },
    handleCloseSuccessSignModal() {
      this.showSuccessSignModal = false;
    },
    signup(event) {
      if (!this.isValidEmail) {
        event.preventDefault();
        return;
      }
      event.preventDefault();
      swal.showLoading();
      const role = "producer";
      api
        .authSignup({ email: this.email, role })
        .then(res => {
          if (res.Error) {
            swal.close();
            if (res.Error === "User with that email already exists") {
              this.modalTitle = "You’re Already Signed Up!";
              this.modalDescription = "A user with that email is already in our system. Please <b>log in</b> or <b>complete registration</b> via the confirmation link provided to your email address. <br><br> Please check your spam/promotions/social folder(s) and mark info@aslice.com as a trusted sender.<br><br> For Tech Support: info@aslice.com";
              this.showSuccessSignModal = true;
            } else {
              const error = res.Error;
              swal.fire({
                icon: "error",
                text: error
              });
            }
          } else {
            swal.close();
            this.modalTitle = MODAl_TEXTES.title;
            this.modalDescription = MODAl_TEXTES.description(this.email);
            this.showSuccessSignModal = true;
            this.email = "";
          }
        })
        .catch(err => {
          swal.close();
          console.log(err);
        });
    },
    scrollToElement(ref) {
      if (this.$refs[ref]) {
        const y = this.$refs[ref].offsetTop - 60;
        window.scroll({
          top: y,
          behavior: "smooth"
        });
      }
    }
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.id &&
      this.$route.query.access_token
    ) {
      this.$bvModal.show("restore-modal");
    }
  }
};
</script>

<style lang="scss" scoped>
.closing-page {
  position: relative;
  font-family: "Graphik App" !important;
  background: #FEFEFD;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/main/images/pattern.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    opacity: 0.05;
    //z-index: -1;
  }

  .internal-overlay-ellipse {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1200px;
    background-image: url("../assets/images/main/images/ellipse-background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    pointer-events: none;
    @media (max-width: 1240px) {
      height: 800px;
    }
    @media (max-width: 768px) {
      height: 500px;
    }
  }

  .overlay-ellipse {
    position: absolute;
    top: 0;
    width: 100%;
    height: 700px;
    background-image: url("../assets/images/main/images/overlay-ellipse.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    pointer-events: none;
    @media (max-width: 768px) {
      height: 300px;
    }
  }

  &__header {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 60px;
    display: flex;
    padding: 0 20px;
    position: fixed;
    align-items: center;
    background: #5b3497;
    justify-content: space-between;

    .login-text {
      color: #c2c1bf;
      margin: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__content {
    position: relative;
    //max-width: 1440px;
    padding: 0 40px 24px;
    margin: 0 auto;
    color: #442671;
    @media (max-width: 768px) {
      padding: 0 20px 24px;
      max-width: 100%;
    }
  }

  &__title {
    font-family: "Graphik App Bold" !important;
    font-size: 287px;
    line-height: 258.3px;
    text-align: center;
    color: #fefefd;
    padding-top: 160px;
    text-transform: uppercase;
    @media (max-width: 1240px) {
      font-size: 180px;
      line-height: 170px;
    }
    @media (max-width: 768px) {
      font-size: 68px;
      line-height: 61.2px;
    }
  }

  .arrow-down {
    margin-bottom: 160px;
    margin-top: 80px;
    @media (max-width: 768px) {
      margin-bottom: 80px;
      margin-top: 40px;
    }
  }

  &__subtitle {
    font-family: "Graphik App" !important;
    font-size: 68px;
    line-height: 74.8px;
    text-align: center;
    color: #442671;
    text-transform: capitalize;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 32px;
      padding: 0 32px;
    }
  }

  &__text-block {
    max-width: 904px;
    margin: 0 auto;
    padding-top: 48px;
    padding-bottom: 160px;
    font-size: 26px;
    line-height: 31.3px;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 40px;
    }

    strong {
      font-family: "Graphik App Bold" !important;
    }

    p {
      margin-bottom: 35px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    a {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__faq-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #91918f;
    border-radius: 4px;
    padding: 15px 20px;
    margin-top: 48px;
    @media (max-width: 768px) {
      margin-top: 48px;
      flex-direction: column;
      gap: 16px;
      padding: 26.5px;
    }
  }

  &__report-block {
    display: flex;
    @media (max-width: 1240px) {
      flex-direction: column;
      padding: 40px 0 80px;
    }

    & > div {
      padding: 80px;
      width: 100%;
      @media (max-width: 1240px) {
        padding: 40px 0 0;
      }
    }

    & > div:first-child {
      border-right: 1px solid #c2c1bf;
      @media (max-width: 1240px) {
        border-right: none;
        padding-bottom: 40px;
        border-bottom: 1px solid #c2c1bf;
      }
    }

    .report-block__title {
      font-family: "Graphik App Bold" !important;
      font-size: 42px;
      line-height: 37.8px;
      text-transform: uppercase;
      margin-bottom: 24px;

      @media (max-width: 768px) {
        font-size: 26px;
        line-height: 23.4px;
      }

      & > div {
        color: #9856fc;
      }
    }

    .report-img {
      max-width: 100%;
      border-radius: 4px;
      max-height: 490px;

      @media (max-width: 768px) {
        object-fit: cover;
        max-height: 330px;
      }
    }

    .report-block__search-box {
      background: #fefefd;
      height: 382px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding: 24px;
      border-radius: 4px;

      .search-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .search-instructions {
        font-size: 11px;
        line-height: 12px;
        color: #91918f;
        margin: 0;
      }

      .search-info {
        font-family: "Graphik App";
        font-size: 10px;
        line-height: 12px;
        color: #91918f;
        margin-bottom: 0;
        text-align: center;

        a {
          color: #91918f;
        }
      }

      .search-box__title {
        font-family: "Graphik App Bold" !important;
        font-size: 16px;
        line-height: 14.4px;
        text-transform: uppercase;
        color: #442671;
        text-align: center;
        margin-bottom: 0;
      }

      .search-box__title-red {
        color: #e32d26;
        margin-bottom: 24px;
      }

      .search-box__title-green {
        color: #29c9be;
        margin-bottom: 24px;
      }

      .button {
        margin-top: 24px;
      }
    }

    p {
      font-family: "Roboto-Mono";
      font-size: 14px;
      line-height: 18.9px;
      margin-bottom: 25px;
    }

    a {
      color: #442671;
      text-decoration: underline;
    }

    img {
      margin-bottom: 24px;
    }
  }

  &__info-block {
    font-family: "Graphik App";
    font-size: 26px;
    line-height: 31px;
    color: #442671;
    padding: 80px;
    background: #f5eeff;
    margin: 24px 0;
    @media (max-width: 1240px) {
      margin-top: 40px;
      margin-bottom: 0;
    }
    @media (max-width: 768px) {
      padding: 20px;
      font-size: 16px;
      line-height: 19px;
    }
    strong {
      font-family: "Graphik App Bold" !important;
    }

    a {
      color: #442671;
      text-decoration: underline;
    }
  }

  &__banner-block {
    margin: 24px 0;
    @media (max-width: 768px) {
      margin: 0;
    }
  }

  .divider {
    border-bottom: 1px solid #c2c1bf;
  }

  .search-field {
    position: relative;
    margin-bottom: 24px;
    max-width: 396px;
    width: 100%;
    @media (max-width: 768px) {
      max-width: 222px;
    }
  }

  .search-input {
    width: 100%;
    padding: 10px 15px 8px 40px;
    font-size: 14px !important;
    line-height: 20px;
    border: 1px solid #c2c1bf;
    border-radius: 50px;
    outline: none;
    color: #a0a0a0;
    font-family: "Graphik App" !important;

    &::placeholder {
      text-transform: uppercase;
      color: #a0a0a0;
      opacity: 1;
      letter-spacing: 0.13em;
    }
  }

  .search-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .email-form {
    width: 100%;
    max-width: 354px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .email-field {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      color: #d6bbfe;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    input {
      padding: 10px 0;
      font-size: 14px !important;
      line-height: 19px;
      color: #1e0036;
      border-color: #5b3497;

      &::placeholder {
        color: #1e0036;
        opacity: 1;
      }
    }
  }
}

.button {
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  color: #fefefd;
  border-radius: 50px;
  letter-spacing: 0.13em;
  background: #442671;
  text-transform: uppercase;
  border: 1px solid #442671;
  font-family: "Graphik-Regular-Web";
  display: flex;
  align-items: center;
  transition: 0.2s ease-in;
}

.button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.button.disabled {
  pointer-events: none;
}

.btn-login {
  background: transparent;
  margin-left: 10px;
  border: 1px solid #c2c1bf;
  padding: 10px 15px;
  height: 28px;
  transition: 0.2s ease-in;

  &:hover {
    opacity: 0.8;
  }
}

.btn-outline {
  color: #442671;
}

.btn-text {
  height: auto;
  background: transparent;
  color: #442671;
  text-decoration: underline;
  border: none;
  text-transform: unset;
  font-family: "Roboto-Mono";
  letter-spacing: initial;
}

.notice-closure {
  gap: 24px;
  padding: 80px !important;
  &__title {
    text-transform: uppercase;
    font-family: "Graphik App Bold" !important;
    font-size: 42px;
    color: #E32D26;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
  &__text {
    margin-bottom: 0;
  }

  @media (max-width: 1440px) {
    padding: 60px !important;
  }
  @media (max-width: 1240px) {
    background: transparent !important;
    height: auto !important;
    padding: 40px 0 !important;
  }
}
</style>
<style lang="scss">
.closing-page {
  .main-page__footer {
    background: transparent !important;
  }
}
</style>
